import React from "react";
import Navbar from "./Navbar";
import franciseCrick from "../images/cricket.jpg";
import project1 from "../images/robot.jpeg";
import project2 from "../images/bot.jpeg";
import project3 from "../images/rubik.jpeg";

import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid, Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography} from "@material-ui/core";

const useStyles = makeStyles({
  mainContainer: {
    background: "#233",
    height: "100%",
  },
  cardContainer: {
    maxWidth: 345,
    margin: "5rem auto",
    height: "400px",
  },
});
const Portfolio = () => {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.mainContainer}>
      <Navbar />
      <Grid container justify="center" spacing={3}>
        {/* Project 0 */}
        <Grid item xs={12} sm={6} lg={3}>
          <Card className={classes.cardContainer}>
            <CardActionArea>
              <CardMedia component="img" alt="project 1" height="140" image={franciseCrick} />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Franchise Cricket App
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  For managing franchise cricket events. using nodeJS, ExpressJS, MongoDB
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary" href="https://github.com/abhishekdipu/FranchiseCricketApp" target="blank">
                Source Code
              </Button>
            </CardActions>
          </Card>
        </Grid>
        {/* Project 0 ends*/}
        {/* Project 1 */}
        <Grid item xs={12} sm={6} lg={3}>
          <Card className={classes.cardContainer}>
            <CardActionArea>
              <CardMedia component="img" alt="project 1" height="140" image={project1} />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Home Automation
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  This was 2nd Year project in college which enable user to switch on/off lights or fans, play/pause/change music just at the tap on
                  smartphone.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary"></Button>
              <Button size="small" color="primary"></Button>
            </CardActions>
          </Card>
        </Grid>
        {/* Project 1 ends*/}
        {/* Project 2 */}
        <Grid item xs={12} sm={6} lg={3}>
          <Card className={classes.cardContainer}>
            <CardActionArea>
              <CardMedia component="img" alt="project 2" height="140" image={project2} />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Arduino Line Follower Robot
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  This was 3rd Year project in college. It was an automated guided vehicle, which follow a black line on a white surface or white line
                  on black surface.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary"></Button>
              <Button size="small" color="primary"></Button>
            </CardActions>
          </Card>
        </Grid>
        {/* Project 2 ends*/}
        {/* Project 3 */}
        <Grid item xs={12} sm={6} lg={3}>
          <Card className={classes.cardContainer}>
            <CardActionArea>
              <CardMedia component="img" alt="project 2" height="140" image={project3} />
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Rubik's Cube Solver
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  This was one of final year project in college where we made a bot prototype which was capable to solved rubik's cube
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary"></Button>
              <Button size="small" color="primary"></Button>
            </CardActions>
          </Card>
        </Grid>
        {/* Project 3 ends*/}
      </Grid>
    </Box>
  );
};

export default Portfolio;
