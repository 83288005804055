import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import GitHub from "@material-ui/icons/GitHub";
import Twitter from "@material-ui/icons/Twitter";
import LinkedIn from "@material-ui/icons/LinkedIn";
import {URL} from "../constants/url";

const useStyles = makeStyles({
  root: {
    "& .MuiBottomNavigationAction-root": {
      minWidth: 0,
      maxWidth: 250,
    },
    "& .MuiSvgIcon-root": {
      fill: "tan",
      "&:hover": {
        fill: "tomato",
        fontSize: "1.8rem",
      },
    },
  },
});

const Footer = () => {
  const classes = useStyles();

  return (
    <BottomNavigation width="auto" style={{background: "#222"}}>
      <BottomNavigationAction className={classes.root} style={{padding: 0}} icon={<LinkedIn />} href={URL.LINKEDIN} target="_blank" />
      <BottomNavigationAction className={classes.root} style={{padding: 0}} icon={<GitHub />} href={URL.GITHUB} target="_blank" />
      <BottomNavigationAction className={classes.root} style={{padding: 0}} icon={<Twitter />} href={URL.TWITTER} target="_blank" />
    </BottomNavigation>
  );
};

export default Footer;
