import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Typography, Box, Button} from "@material-ui/core";
import Navbar from "./Navbar";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#233",
  },
  timeLine: {
    position: "relative",
    padding: "1rem",
    margin: "0 auto",
    "&:before": {
      content: "''",
      position: "absolute",
      height: "100%",
      border: "1px solid tan",
      right: "4px",
      top: 0,
    },
    "&:after": {
      content: "''",
      display: "table",
      clear: "both",
    },
    [theme.breakpoints.up("md")]: {
      padding: "2rem",
      "&:before": {
        left: "calc(50% - 1px)",
        right: "auto",
      },
    },
  },
  timeLineItem: {
    padding: "1rem",
    borderBottom: "2px solid tan",
    position: "relative",
    margin: "1rem 3rem 1rem 1rem",
    clear: "both",
    "&:after": {
      content: "''",
      position: "absolute",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      right: "-0.625rem",
      top: "calc(50% - 5px)",
      borderStyle: "solid",
      borderColor: "tomato tomato transparent transparent",
      borderWidth: "0.625rem",
      transform: "rotate(45deg)",
    },
    [theme.breakpoints.up("md")]: {
      width: "44%",
      margin: "1rem",
      "&:nth-of-type(2n)": {
        float: "right",
        margin: "1rem",
        borderColor: "tan",
      },
      "&:nth-of-type(2n):before": {
        right: "auto",
        left: "-0.625rem",
        borderColor: "transparent transparent tomato tomato",
      },
    },
  },
  timeLineYear: {
    textAlign: "center",
    maxWidth: "9.375rem",
    margin: "0.3rem 0 auto",
    fontSize: "1.8rem",
    background: "tomato",
    color: "white",
    lineHeight: 1,
    padding: "0.5rem 0 1rem",
    "&:before": {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      margin: "0 auto",
      "&:nth-of-type(2n)": {
        float: "none",
        margin: "0 auto",
      },
      "&:nth-of-type(2n):before": {
        display: "none",
      },
    },
  },
  heading: {
    color: "tomato",
    padding: "3rem 0",
    textTransform: "uppercase",
  },
  subHeading: {
    color: "white",
    padding: "0",
    textTransform: "uppercase",
  },
}));

const Resume = () => {
  const classes = useStyles();
  return (
    <>
      <Navbar />

      <Box component="header" className={classes.mainContainer}>
        {/* apply download button */}
        <div align="right">
          <Button
            align="right"
            color="inherit"
            variant="contained"
            startIcon={<CloudDownloadIcon />}
            href={"https://drive.google.com/file/d/1gS1Qzk83XtR2hKio7GfJOclkZS42Qlg-/view?usp=sharing"}
            target="blank"
          >
            Download
          </Button>
        </div>

        <Typography variant="h4" align="center" className={classes.heading}>
          work and education
        </Typography>

        <Box component="div" className={classes.timeLine}>
          {/**--------------- POWERSCHOOL SDE-2------ */}
          <Typography variant="h2" className={`${classes.timeLineYear} ${classes.timeLineItem}`}>
            Present
          </Typography>
          <Box component="div" className={classes.timeLineItem}>
            <Typography variant="h5" aligh="center" className={classes.subHeading}>
              Software Development Engineer 2
            </Typography>
            <Typography variant="body1" aligh="center" style={{color: "tomato"}}>
              PowerSchool
            </Typography>
            <Typography variant="subtitle1" aligh="center" style={{color: "tan"}}>
              <ul>
                <li>Programming Languages : JavaScript, TypeScript</li>
                <li>Technology Stacks: Node.js, Express.js, React.js, HTML5, CSS3, Selenium, Jest, Enzyme, Ant-Design, AWS, AWS-CDK</li>
                <li>Databases: MySql, Sql Server, MongoDB</li>
                <li>Tools: VSCode</li>
              </ul>
            </Typography>
          </Box>
          {/**---------------MINDTREE senior software engineer------ */}
          <Typography variant="h2" className={`${classes.timeLineYear} ${classes.timeLineItem}`}>
            2021
          </Typography>
          <Box component="div" className={classes.timeLineItem}>
            <Typography variant="h5" aligh="center" className={classes.subHeading}>
              Senior Software Engineer
            </Typography>
            <Typography variant="body1" aligh="center" style={{color: "tomato"}}>
              Mindtree Ltd
            </Typography>
            <Typography variant="subtitle1" aligh="center" style={{color: "tan"}}>
              <ul>
                <li>Programming Languages : Java, GroovyScript, JavaScript, Python</li>
                <li>Technology Stacks: Node.js, Express.js, React.js, HTML5, CSS3, Selenium, Jest</li>
                <li>Databases: MySql, Sql Server, MongoDB</li>
                <li>Tools: VSCode, Eclipse, Postman, SoapUI, ReadyAPI, Katalon Studio</li>

                <li>
                  Certifications :
                  <ul>
                    <li>Programming Foundations with JavaScript, HTML and CSS : Duke University</li>
                    <li>Responsive Website Basics: Code with HTML, CSS, and JavaScript : University of London</li>
                    <li>React (Fundamentals + The Big Picture + Getting Started + React Hooks) : Pluralsight</li>
                    <li>M001: MongoDB Basics : MongoDB University</li>
                    <li>Rest Assured (Rest API Automation Rest Assured Serenity BDD) : IIHT Ltd</li>
                    <li> Python (Getting Started + Python Fundamentals + The Big Picture) : Pluralsight </li>
                    <li> Selenium Cucumber (BDD Framework Automation with Java) : IIHT Ltd </li>
                    <li> NodeJS (CutShort Certified NodeJS) : CutShort </li>
                  </ul>
                </li>
                <li>
                  Awards/Recognitions :
                  <ul>
                    <li>A Team Award(4x)</li>
                    <li>Unstoppable Award(1x)</li>
                    <li>Partnership Award(1x)</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Box>
          {/**--------------- college------ */}
          <Typography variant="h2" className={`${classes.timeLineYear} ${classes.timeLineItem}`}>
            2018
          </Typography>
          <Box component="div" className={classes.timeLineItem}>
            <Typography variant="h5" aligh="center" className={classes.subHeading}>
              College Student
            </Typography>
            <Typography variant="body1" aligh="center" style={{color: "tomato"}}>
              <div>Maulana Abul Kalam Azad University of Technology, West Bengal formerly WBUT</div>
              Techno India Saltlake
            </Typography>
            <Typography variant="subtitle1" aligh="center" style={{color: "tan"}}>
              <ul>
                <li>Branch : Electronics and Communication Engineering </li>
                <li>DGPA : 8.04 </li>
                <li>Internship : All India Radio </li>
                <li>Won robotics competition at TISL</li>
                <li>Finalist 2017 E-Yantra at IIT Bombay</li>
                <li>Position Hold : Member of Cultural and Technical Committee</li>
              </ul>
            </Typography>
          </Box>
          {/*---------------------- school----------------------- */}
          <Typography variant="h2" className={`${classes.timeLineYear} ${classes.timeLineItem}`}>
            2014
          </Typography>
          <Box component="div" className={classes.timeLineItem}>
            <Typography variant="h5" aligh="center" className={classes.subHeading}>
              School Student
            </Typography>
            <Typography variant="body1" aligh="center" style={{color: "tomato"}}>
              D.A.V. PUBLIC SCHOOL, KOYLANAGAR DHANABD
            </Typography>
            <Typography variant="subtitle1" aligh="center" style={{color: "tan"}}>
              <ul>
                <li>Higher Secondary Certificate / INTERMEDIATE / 12th std.</li>
                <li>Major Subjects : Maths , Physics, chemistry, English, Hindi, Physical Education</li>
                <li>Grade : 82.7%</li>
                <li>Position Hold : House Captain</li>
              </ul>
            </Typography>
            <Typography variant="body1" aligh="center" style={{color: "tomato"}}>
              D.A.V. CENTENARY PUBLIC SCHOOL, BANIAHIR DHANBAD
            </Typography>
            <Typography variant="subtitle1" aligh="center" style={{color: "tan"}}>
              <ul>
                <li>Secondary School Certificate / SSC / Matriculation examination / 10th std.</li>
                <li>
                  Major Subjects : Maths , Physics, chemistry, Biology, English, Hindi, Social Science, Sanskrit, Moral Education, History, Geography,
                  Economics, Civics, Computer Applications Physical Education
                </li>
                <li>Grade : 8.8/10</li>
              </ul>
            </Typography>
          </Box>

          {/*---------------------- 2020 year ends------------------------ */}
        </Box>
      </Box>
    </>
  );
};

export default Resume;
