import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Typography, Avatar, Grid, Box} from "@material-ui/core";
import Typed from "react-typed";
import avatar from "../avatar.png";
import {TECH_STACKS} from "../constants/message";

//css styles
const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: theme.spacing(1),
  },
  title: {
    color: "tomato",
  },
  subtitle: {
    color: "tan",
    marginBottom: "3rem",
  },
  typedContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    textAlign: "center",
    zindex: 1,
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <Box className={classes.typedContainer}>
      <Grid container justify="center">
        <Avatar src={avatar} alt="Abhishek Kumar" className={classes.avatar} />
      </Grid>

      <Typography variant="h3" className={classes.title}>
        <Typed strings={["Abhishek Kumar"]} typeSpeed={40} />
      </Typography>
      <br />
      <Typography variant="h4" className={classes.subtitle}>
        <Typed strings={TECH_STACKS} typeSpeed={40} backSpeed={60} loop />
      </Typography>
    </Box>
  );
};

export default Header;
