export const TECH_STACKS = [
  "Node.js",
  "JavaScript",
  "TypeScript",
  "Serverless Backend Development",
  "AWS",
  "AWS-CDK",
  "Docker",
  "Git",
  "AWS-Lambda",
  "API Gateway",
  "DynamoDB",
  "S3",
  "EC2",
  "SQS",
  "CloudFront",
  "ECS",
  "ECR",
  "Fargate",
  "AWS-OpenSearch",
  "CloudWatch",
  "DynamoDB-OneTable",
  "Express.js",
  "React.js",
  "HTML5",
  "CSS3",
  "SCSS",
  "Redux.js ",
  "Redux-toolkit",
  "Material-UI",
  "Ant-Design",
  "Markdown",
  "Webpack",
  "MySql",
  "Sql Server",
  "TypeORM",
  "MongoDB",
  "Mongoose",
  "Jest",
  "Sinon",
  "Enzyme",
  "Splunk",
  "Datadog",
  "NewRelic",
  "Java",
  "C",
  "Python",
  "Cypress",
  "Selenium",
  "Katalon",
  "UI Automation",
  "API Automation",
];
