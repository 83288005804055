import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {TextField, Typography, Button, Grid, Box} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import Navbar from "./Navbar";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// import saveMessageToDB from "../api/message";
// import _ from "lodash";

//for email implementation
import emailjs from "emailjs-com";

const useStyles = makeStyles((theme) => ({
  form: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
  },
  button: {
    marginTop: "1rem",
    color: "tomato",
    borderColor: "tomato",
  },
}));
const InputField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "tomato",
    },
    "& label": {
      color: "tan",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "tan",
      },
      "&:hover fieldset": {
        borderColor: "tan",
      },
      "&.Mui-focused fieldset": {
        borderColor: "tan",
      },
    },
  },
})(TextField);

//for snackbar
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Contacts = () => {
  const classes = useStyles();

  // //for save messages to db -
  // const initialMessageData = { name: "", email: "", subject: "", message: "" };
  // const [messageData, setMessageData] = React.useState(initialMessageData);

  //for snackbar
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState(""); //what type of snackbar to display
  const [message, setMessage] = React.useState("");

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  //for email implementation
  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm("gmail", "template1", e.target, "user_UrlNOGa7vXKnJDCMFIHxq").then(
      (result) => {
        // console.log(error.text);
        setSeverity("success");
        setMessage("Thank you for writting to me, will get back to you.");
        setOpen(true);
        // saveMessageToDB(
        //   _.pick(messageData, ["name", "email", "subject", "message"])
        // );
        // console.log(messageData);
      },
      (error) => {
        // console.log(error.text);
        setSeverity("error");
        setMessage("Sorry, Something went wrong");
        setOpen(true);
      }
    );
    e.target.reset();
  }

  return (
    <Box component="div" style={{background: "#233", height: "100vh"}}>
      <Navbar />
      <Grid container justify="center">
        <Box component="form" className={classes.form} onSubmit={sendEmail}>
          <Typography
            variant="h5"
            style={{
              color: "tomato",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Get In Touch
          </Typography>
          <InputField
            name="name"
            required
            autoFocus={true}
            fullWidth={true}
            label="Name"
            variant="outlined"
            margin="dense"
            size="medium"
            inputProps={{style: {color: "white"}}}
            // value={messageData.name}
            // onChange={(e) =>
            //   setMessageData({ ...setMessageData, name: e.target.value })
            // }
          />
          <br />
          <InputField
            required
            name="email"
            type={"email"}
            fullWidth={true}
            label="Email"
            variant="outlined"
            margin="dense"
            size="medium"
            inputProps={{style: {color: "white"}}}
            // value={messageData.email}
            // onChange={(e) =>
            //   setMessageData({ ...setMessageData, email: e.target.value })
            // }
          />
          <br />
          <InputField
            name="subject"
            fullWidth={true}
            label="Subject"
            variant="outlined"
            margin="dense"
            size="medium"
            inputProps={{style: {color: "white"}}}
            // value={messageData.subject}
            // onChange={(e) =>
            //   setMessageData({ ...setMessageData, subject: e.target.value })
            // }
          />
          <br />
          <InputField
            name="message"
            type="text"
            fullWidth={true}
            label="Message"
            variant="outlined"
            margin="dense"
            size="medium"
            multiline={true}
            rows={5}
            inputProps={{style: {color: "white"}}}
            // value={messageData.message}
            // onChange={(e) =>
            //   setMessageData({ ...setMessageData, message: e.target.value })
            // }
          />
          <br />
          <Button
            // onClick={handleClick}
            variant="outlined"
            fullWidth={true}
            endIcon={<SendIcon />}
            className={classes.button}
            type="submit"
          >
            contact me
          </Button>
        </Box>
      </Grid>

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Contacts;
